var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('div',{staticClass:"px-4"},[_c('loading',{attrs:{"is-show":_vm.loadingDialog,"is-done":_vm.loadingFinished,"is-html":_vm.isHtml,"message":_vm.loadingMessage},on:{"close-dialog":_vm.closeDialog}}),_c('details-form',{attrs:{"is-show":_vm.isShowDetails,"details":_vm.details},on:{"close-dialog":_vm.closeDialog}}),_c('v-container',{staticClass:"container--fluid grid-list-md text-center"},[_c('h1',{attrs:{"data-cy":"account-transaction-title"}},[_vm._v("Account Transactions")])]),_c('v-card',{staticClass:"pa-1",attrs:{"elevation":"2","outlined":"","shaped":"","tile":""}},[_c('v-card-title',[_c('v-container',{},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"font-weight-bold mr-2 mt-4 text-left",attrs:{"data-cy":"account-details"}},[_vm._v(" Account #: "+_vm._s(_vm.id)+" "+_vm._s(_vm.status)+" ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mt-2 mr-2",attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"color":"default","data-cy":"back","to":{ name: 'account' }}},[_vm._v(" Back to Savings Account ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[(_vm.hasPermission('ROLE_CREDIT_MAKER') && this.status === 'Active')?_c('v-btn',{staticClass:"mt-2 mr-2",attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"color":"success","data-cy":"deposit"},on:{"click":function($event){$event.stopPropagation();return _vm.showAccountTransactionForm('DEPOSIT')}}},[_vm._v(" Deposit ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.hasPermission('ROLE_DEBIT_MAKER') && this.status === 'Active')?_c('v-btn',{staticClass:"mt-2 mr-2",attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"color":"success","data-cy":"withdrawal"},on:{"click":function($event){$event.stopPropagation();return _vm.showAccountTransactionForm('WITHDRAWAL')}}},[_vm._v(" Withdrawal ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[(
                    _vm.hasPermission('ROLE_TRANSFER_MAKER') && this.status === 'Active'
                  )?_c('v-btn',{staticClass:"mt-2 mr-2",attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"color":"success","data-cy":"transfer"},on:{"click":function($event){$event.stopPropagation();return _vm.showAccountTransactionForm('TRANSFER')}}},[_vm._v(" Transfer ")]):_vm._e(),_c('account-transaction-form',{ref:"form",attrs:{"data-cy":"account-transaction-form","selected-item":_vm.selectedAccountTransaction,"is-show":_vm.showFormDialog,"form-type":_vm.accountTransactionFormType},on:{"close-dialog":_vm.closeDialog,"save-account-transaction":_vm.saveAccountTransaction}})],1)],1)],1)],1)],1),_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:'trx_reqs'},[_vm._v("Transaction Requests")]),_c('v-tab',{key:'trx_history'},[_vm._v("Transaction History")]),_c('v-tab',{key:'status_history'},[_vm._v("Status Update History")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
        _vm.hasPermission('ROLE_READ_TRANSACTION_REQUESTS') ||
        _vm.hasPermission('ROLE_CREDIT_MAKER') ||
        _vm.hasPermission('ROLE_CREDIT_APPROVER') ||
        _vm.hasPermission('ROLE_DEBIT_MAKER') ||
        _vm.hasPermission('ROLE_DEBIT_APPROVER') ||
        _vm.hasPermission('ROLE_TRANSFER_MAKER') ||
        _vm.hasPermission('ROLE_TRANSFER_APPROVER')
      )?_c('v-tab-item',{key:'trx_reqs',staticClass:"pt-4"},[_c('v-data-table',{attrs:{"hide-default-header":true,"headers":_vm.headersRequest,"items":_vm.displayTransactionRequests,"search":_vm.searchRequests,"loading":_vm.loading,"options":_vm.optionRequests,"server-items-length":_vm.totalTransactionRequests},on:{"update:options":function($event){_vm.optionRequests=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var headers = ref.props.headers;
return [_c('thead',[_c('tr',{attrs:{"colspan":headers.length}},_vm._l((headers),function(header){return _c('th',{key:header.text},[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(header.text))])])}),0)])]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 mb-10",attrs:{"append-icon":"mdi-magnify","outlined":"","label":"Search","data-cy":"search-requests","hint":"*Search current page only","persistent-hint":""},model:{value:(_vm.searchRequests),callback:function ($$v) {_vm.searchRequests=$$v},expression:"searchRequests"}})]},proxy:true},{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,attrs:{"colspan":_vm.headers.length}},[_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.transactionType)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" ₱ "+_vm._s(_vm.formatMoney(item.amount))+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.dateCreated)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.source ? ((item.source) + " - " + (item.sourceAccountNumber)) : "n/a")+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.destination ? ((item.destination) + " - " + (item.destinationAccountNumber)) : "n/a")+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.status === 1 ? "APPROVED" : item.status === 2 ? "REJECTED" : "PENDING")+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"green","data-cy":"view-request-details"},on:{"click":function($event){$event.stopPropagation();return _vm.viewDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-magnify-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.enableApprovalButtons(item.status, item.transactionType))?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"green","data-cy":"approve-request"},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedRequest(item, 'APPROVE')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.enableApprovalButtons(item.status, item.transactionType))?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"green","data-cy":"reject-request"},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedRequest(item, 'REJECT')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reject")])])],1)])])}),0)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Found no results. ")])]},proxy:true}],null,false,1514000728)}),_c('v-dialog',{attrs:{"data-cy":"approve-dialog","max-width":"500","retain-focus":false},model:{value:(_vm.dialogApprove),callback:function ($$v) {_vm.dialogApprove=$$v},expression:"dialogApprove"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Approve transaction request?")]),_c('v-card-text',[_vm._v(" Approve "+_vm._s(_vm.selectedRequest.transactionType)+" request for transaction ID: "+_vm._s(_vm.selectedRequest.id)+" amounting to ₱ "+_vm._s(_vm.formatMoney(_vm.selectedRequest.amount))+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","data-cy":"approve-request"},on:{"click":function($event){$event.stopPropagation();return _vm.setAccountTransactionApproval({
                referenceNumber: _vm.selectedRequest.referenceNumber,
                transactionType: _vm.selectedRequest.transactionType,
                approval: 'approve',
              })}}},[_vm._v(" Confirm ")]),_c('v-btn',{attrs:{"color":"gray darken-1","text":""},on:{"click":function($event){_vm.dialogApprove = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-dialog',{attrs:{"data-cy":"reject-dialog","max-width":"500","retain-focus":false},model:{value:(_vm.dialogReject),callback:function ($$v) {_vm.dialogReject=$$v},expression:"dialogReject"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Reject transaction request?")]),_c('v-card-text',[_vm._v(" Reject "+_vm._s(_vm.selectedRequest.transactionType)+" request for transaction ID: "+_vm._s(_vm.selectedRequest.id)+" amounting to ₱ "+_vm._s(_vm.formatMoney(_vm.selectedRequest.amount))+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","data-cy":"reject-request"},on:{"click":function($event){$event.stopPropagation();return _vm.setAccountTransactionApproval({
                referenceNumber: _vm.selectedRequest.referenceNumber,
                transactionType: _vm.selectedRequest.transactionType,
                approval: 'reject',
              })}}},[_vm._v(" Confirm ")]),_c('v-btn',{attrs:{"color":"gray darken-1","text":""},on:{"click":function($event){_vm.dialogReject = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1):_vm._e(),(_vm.hasPermission('ROLE_READ_TRANSACTIONS'))?_c('v-tab-item',{key:'trx_history',staticClass:"pt-4"},[_c('v-data-table',{attrs:{"hide-default-header":true,"headers":_vm.headers,"items":_vm.displayAccountTransactions,"search":_vm.searchTransactions,"loading":_vm.loading,"options":_vm.optionTransactions,"server-items-length":_vm.totalAccountTransactions},on:{"update:options":function($event){_vm.optionTransactions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var headers = ref.props.headers;
return [_c('thead',[_c('tr',{attrs:{"colspan":headers.length}},_vm._l((headers),function(header){return _c('th',{key:header.text},[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(header.text))])])}),0)])]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 mb-10",attrs:{"append-icon":"mdi-magnify","outlined":"","label":"Search","data-cy":"search-transaction","hint":"*Search current page only","persistent-hint":""},model:{value:(_vm.searchTransactions),callback:function ($$v) {_vm.searchTransactions=$$v},expression:"searchTransactions"}})]},proxy:true},{key:"body",fn:function(ref){
              var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,attrs:{"colspan":_vm.headers.length}},[_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.transactionType)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" ₱ "+_vm._s(_vm.formatMoney(item.amount))+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.dateCreated)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.source ? ((item.source) + " - " + (item.sourceAccountNumber)) : "n/a")+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.destination ? ((item.destination) + " - " + (item.destinationAccountNumber)) : "n/a")+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.runningBalance ? Number(item.runningBalance).toLocaleString() : "-")+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"green","data-cy":"view-transaction-details"},on:{"click":function($event){$event.stopPropagation();return _vm.viewDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-magnify-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])])],1)])])}),0)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Found no results. ")])]},proxy:true}],null,false,1868888788)})],1):_vm._e(),(_vm.hasPermission('ROLE_READ_TRANSACTIONS'))?_c('v-tab-item',{key:'status_history'},[_c('status-update-history',{attrs:{"account-id":String(_vm.id)}})],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }