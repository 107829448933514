<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :server-items-length="totalItems"
    :loading="loading"
    class="elevation-1"
    :search="search"
  ></v-data-table>
</template>

<script>
import API from "@/api/API.js";

export default {
  name: "StatusUpdateHistory",
  props: {
    accountId: String,
  },
  data() {
    return {
      search: '',
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Remarks',
          sortable: false,
          value: 'remarks',
        },
        {
          text: 'Updated',
          sortable: false,
          value: 'updated',
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        console.log(JSON.stringify(this.options));
        API.getAccountStatusHistory({
          accountId: Number(this.$props.accountId),
          pageNumber: this.options.page - 1,
          limit: this.options.itemsPerPage,
        }).then((data) => {
          console.log(JSON.stringify(data));
          this.items = data.items;
          this.totalItems = data.totalItems;
          this.loading = false;
        });
      },
      deep: true,
    },
  },
  methods: {},
}
</script>

<style scoped>

</style>
