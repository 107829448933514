<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline" v-if="formType === 'DEPOSIT'">
            Create Deposit Request
          </span>
          <span class="headline" v-if="formType === 'WITHDRAWAL'">
            Create Withdrawal Request
          </span>
          <span class="headline" v-if="formType === 'TRANSFER'">
            Create Transfer Request
          </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-stepper v-model="e1">
              <v-stepper-header v-if="formType === 'TRANSFER'">
                <v-stepper-step :complete="e1 > 1" step="1">Office</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">Client</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" :complete="isFormComplete">Account</v-stepper-step>
              </v-stepper-header>
              <v-stepper-header v-if="formType !== 'TRANSFER'">
                <v-stepper-step step="1" :complete="isFormComplete">Amount</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="formEntryOne">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 v-if="formType === 'TRANSFER'">
                          <v-autocomplete
                            v-model="form.toOffice"
                            :items="toOfficeOptions"
                            data-cy="office"
                            item-text="name"
                            item-value="id"
                            label="Office*"
                            required
                            :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 v-if="formType !== 'TRANSFER'">
                          <v-text-field
                            data-cy="amount"
                            label="Amount*"
                            prefix="₱"
                            type="number"
                            v-model="form.transactionAmount"
                            required
                            :rules="[rules.requiredField]"></v-text-field>
                        </v-flex>
                        <v-flex xs12 v-if="formType !== 'TRANSFER'">
                          <v-text-field
                            data-cy="remarks"
                            label="Remarks"
                            v-model="form.remarks"></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <div class="text-right stepper-1-btns">
                      <v-btn color="primary" @click="save" v-if="formType !== 'TRANSFER'">
                        Save
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="next(1)"
                        v-if="formType === 'TRANSFER'"
                      >
                        Continue
                      </v-btn>
                    </div>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="2" v-if="formType === 'TRANSFER'">
                  <v-form ref="formEntryTwo">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-autocomplete
                            v-model="form.toClient"
                            :items="toClientOptions"
                            item-text="displayName"
                            item-value="id"
                            data-cy="client"
                            label="Client*"
                            required
                            :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <div class="text-right stepper-1-btns">
                      <v-btn
                        class="mr-2"
                        @click="e1 = 1"
                        color="warning"
                        v-if="formType === 'TRANSFER'"
                      >Back
                      </v-btn
                      >
                      <v-btn color="primary" @click="next(2)">Continue</v-btn>
                    </div>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 v-if="formType === 'TRANSFER'">
                        <v-autocomplete
                          v-model="form.toAccount"
                          :items="toAccountOptions"
                          item-text="accountNo"
                          data-cy="account-number"
                          item-value="id"
                          label="Account*"
                          required
                          :rules="[rules.requiredSelection]"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          data-cy="amount"
                          label="Amount*"
                          prefix="₱"
                          type="number"
                          v-model="form.transactionAmount"
                          required
                          :rules="[rules.requiredField]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          data-cy="remarks"
                          label="Remarks*"
                          v-model="form.remarks"
                          required
                          :rules="[rules.requiredField]"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-flex class="text-right">
                    <v-btn
                      class="mr-2"
                      @click="e1 = 2"
                      color="warning"
                      v-if="formType === 'TRANSFER'"
                    >Back
                    </v-btn
                    >
                    <v-btn
                      color="primary"
                      data-cy="save-account-transaction"
                      @click="save"
                    >Save
                    </v-btn
                    >
                  </v-flex>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>

          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import API from "@/api/API.js";

export default {
  props: {
    isShow: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
  },
  computed: {
    populatedForm() {
      return {
        accountId: this.form.accountId,
        transactionAmount: this.form.transactionAmount,
        remarks: this.form.remarks,
        formType: this.formType,

        toOffice: this.form.toOffice,
        toClient: this.form.toClient,
        toAccount: this.form.toAccount,
      };
    },
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
        setTimeout(async () => {
          this.setData();
        }, 1000);
      },
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    deep: true,
  },
  data() {
    return {
      toOfficeOptions: [],
      toClientOptions: [],
      toAccountOptions: [],
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,
      form: {},
      rules: {
        requiredField: (v) => !!v || "Required field.",
        requiredSelection: (v) => !!v || "Required at least one selection",
        properEmail: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
      },
      e1: 1,
      isFormComplete: false,
    };
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    async setData() {
      try {
        const listResponse = await API.transferTemplate(this.form);
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          console.log(listResponse);
          // convert object to array
          this.toOfficeOptions = Object.keys(listResponse.data.toOfficeOptions)
            .map((key) => {
              return listResponse.data.toOfficeOptions[key];
            })
            .filter((notUndefined) => notUndefined !== undefined);
          if (listResponse.data.toClientOptions) {
            this.toClientOptions = Object.keys(listResponse.data.toClientOptions)
              .map((key) => {
                return listResponse.data.toClientOptions[key];
              })
              .filter((notUndefined) => notUndefined !== undefined);
          }
          if (listResponse.data.toAccountOptions) {
            this.toAccountOptions = Object.keys(listResponse.data.toAccountOptions)
              .map((key) => {
                return listResponse.data.toAccountOptions[key];
              })
              .filter((notUndefined) => notUndefined !== undefined);
          }
          console.log(this.toOfficeOptions);
          console.log(this.toClientOptions);
          console.log(this.toAccountOptions);
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    next(item) {
      if (item == 1 && this.$refs.formEntryOne.validate()) {
        this.e1 = 2;
        this.toClientOptions = [];
        this.form.toClient = undefined;
      } else if (item == 2 && this.$refs.formEntryTwo.validate()) {
        this.e1 = 3;
        this.toAccountOptions = [];
        this.form.toAccount = undefined;
      }
      this.setData();
    },
    save() {
      if (this.formType !== "TRANSFER") {
        if (this.$refs.formEntryOne.validate()) {
          this.isFormComplete = true;
          this.$emit("save-account-transaction", this.populatedForm);
          this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
          });
        }
      } else {
        if (this.$refs.formEntry.validate()) {
          this.isFormComplete = true;
          this.$emit("save-account-transaction", this.populatedForm);
          this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      this.e1 = 1;
      this.isFormComplete = false;
      if (this.formType === "TRANSFER") {
        this.form = {
          accountId: this.selectedItem.accountId,
          transactionAmount: undefined,
          remarks: undefined,
          toOffice: undefined,
          toClient: undefined,
          toAccount: undefined,
        };
      } else {
        this.form = {
          accountId: this.selectedItem.accountId,
          transactionAmount: undefined,
          remarks: undefined,
        };
      }
    },
  },
};
</script>
